<template>
  <div>
    <portal to="body-top">
      <customer-name title="" />
    </portal>
    <l-table
      ref="refAttachmentTable"
      :module-name="MODULE_NAME"
      :table-columns="attachmentTrashTableColumns"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/getTrashedAttachment`
      }"
    >
      <template #cell(actions)="{ data }">
        <div class="text-nowrap">
          <feather-icon
            icon="LLoadIcon"
            size="22"
            class="cursor-pointer mr-1 border-dotted"
            @click="recoverFromTrash(data.item)"
          />
          <feather-icon
            icon="LEyeIcon"
            size="24"
            class="cursor-pointer mr-1 border-dotted"
            @click="showAttachment(data.item)"
          />
        </div>
      </template>
      <template #filter>
        <button-dashed @click="$router.push({ name: 'home-customers-attachment-list' })">
          <feather-icon
            icon="LDownloadIcon"
            size="16"
            class="lightIcon"
          />
          {{ $t('Attachments') }}
        </button-dashed>
      </template>

      <template #cell(updated_at)="{ data }">
        {{ new Date(data.value).toLocaleString('en-US', dateOptions) }}
      </template>
    </l-table>

    <b-modal
      id="attachment-recover-from-trash-modal"
      centered
      hide-header
      body-class="l-modal__body"
      footer-class="l-modal-footer"
      ok-title="Recover"
      ok-variant="success"
      :ok-disabled="isOkTrashBtnActive"
      :cancel-disabled="isCancelTrashBtnActive"
      cancel-variant="outline-primary"
      @ok="postRecoverFromTrash"
    >
      <b-overlay :show="isMovingToTrash">
        <div class="l-modal__container">
          <b-row>
            <b-col cols="12">
              <h3 class="l-modal__title">
                Are you sure you want to recover this attachment from the trash?
              </h3>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-modal>
    <portal to="body-footer">
      <div class="d-flex mt-2 pb-1 justify-content-between">
        <div>
          <b-button
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
            @click="backToList"
          >
            {{ $t('Back to List') }}
          </b-button>
        </div>
      </div></portal>
  </div>
</template>

<script>

import LTable from '@/views/components/LTable/LTable.vue'
import config from '@/views/main/customers/view/attachments/config'
import {
  BModal, BRow, BCol, BOverlay, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import { useToast } from 'vue-toastification/composition'
import ButtonDashed from '@/@core/components/b-button-dashed/ButtonDashed.vue'
import CustomerName from '../../../components/CustomerName'

export default {
  name: 'AttachmentsRecoverList',
  components: {
    BButton,
    LTable,
    BModal,
    BRow,
    BCol,
    BOverlay,
    ButtonDashed,
    CustomerName,
  },
  data() {
    return {
      isMovingToTrash: false,
      trashItemId: null,
    }
  },
  computed: {
    isOkTrashBtnActive() {
      return this.isMovingToTrash
    },
    isCancelTrashBtnActive() {
      return this.isMovingToTrash
    },
  },
  methods: {
    backToList() {
      this.$router.back()
    },
    showModalRecoverFromTrash() {
      this.$bvModal.show('attachment-recover-from-trash-modal')
    },
    hideModalRecoverFromTrash() {
      this.$bvModal.hide('attachment-recover-from-trash-modal')
    },
    showAttachment({ url }) {
      window.open(url, '_blank')
    },
    recoverFromTrash({ id }) {
      this.showModalRecoverFromTrash()
      this.trashItemId = id
    },
    postRecoverFromTrash(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.isMovingToTrash = true
      this.$store.dispatch(`${this.MODULE_NAME}/recover-from-trash`, { id: this.trashItemId })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('Error fetching trashed attachment list', { module: this.MODULE_NAME }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.hideModalRecoverFromTrash()
          this.$refs.refAttachmentTable.refetchData()
          this.isMovingToTrash = false
        })
    },
  },
  setup() {
    const { attachmentTrashTableColumns, dateOptions, MODULE_NAME } = config()
    const toast = useToast()
    return {
      toast,
      dateOptions,
      attachmentTrashTableColumns,
      MODULE_NAME,
    }
  },
}
</script>
